/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, State} from 'vuex-class'
import { CustomerState } from '@/store/customer/types';
const namespace: string = 'customer';

export default class  extends Vue {
	@State('customer') customer: CustomerState | undefined;
	@Getter('getCryptoCurrency', {namespace}) getCryptoCurrency: any;
	@Getter('getBalanceReplenishment', {namespace}) getBalanceReplenishment: any;
	@Mutation('setTypeCrypto', {namespace}) setTypeCrypto: any;


	nameCryptoCurrency: string = '';

	arrayCryptoCurrency: Array<any> = [];

	handleBillFormed(type: string) {
		this.setTypeCrypto(type);
		this.setTypeCrypto(this.nameCryptoCurrency);
		this.$router.push({name: 'bill-formed'}).then(r => r);
	}
}
